.footer {
    padding-bottom: 24px;
    padding-top: 12px;
    margin-top: var(--s-xl);
    background-color: var(--footer-color);
    border-top: 1px solid var(--c-text);
}

.item + .item {
    margin-left: 20px;
}

.items {
    display: flex;
    width: 100%;
}

@media (min-width: 400px) {
    .items {
        width: auto;
    }
}

@media (min-width: 600px) {
    .code {
        display: inline-block !important;
    }
}
