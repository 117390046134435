{
    "home": {
        "title": "I am here with my presence, curiosity, and some necessary chaos. Operating in the realms of the unconventional and experimental, I aim to bring back the balance between you and the surrounding nature.. So both can finally flourish in their full power again. Let's connect for a while and figure it out. When did you lose your curiosity? This trip on earth is supposed to be enjoyable. Maybe I can make you remember…"
    },
    "about": {
        "title": "About the Course",
        "intro": "My intuition guided me towards shaping the role of the contemporary shaman, towards creating a new myth. Through methods of art, intuition, meditation, and other more traditional shamanic tools, I offer help to bring back balance. To inspire and to enable you to gain your power back to act and create in this world."
    },
    "code": {
        "title": "Code of Ethics"
    }
}
