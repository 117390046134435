import { h } from 'preact'
import { Link as RouterLink } from 'preact-router'
import { getRoutesByLocale } from '../../core/Pages'
import useLocale from '../../hooks/useLocale'
import Container from '../Container/Container'
import Headline from '../Headline/Headline'
import Link from '../Link/Link'
import {NoMatch} from '../Matches/Matches'
import * as classes from './Footer.module.css'

const Footer = () => {
    const locale = useLocale()
    const routes = getRoutesByLocale(locale)

    const showSocial = false

    return (
        <footer className={classes.footer}>
            <Container>
                <NoMatch pathToMatch={routes.REGISTER}>
                    <Link href={routes.REGISTER} primary> Register here </Link>
                </NoMatch>

                <div style={{ display: 'flex', marginTop: "24px" }}>
                    <ul
                        className={classes.items}
                        style={{
                            display: 'flex',
                        }}
                    >
                        <li className={classes.item}>
                            <RouterLink href={routes.CODE}>
                                🍵{' '}
                                <span
                                    style={{
                                        display: 'inline-block',
                                    }}
                                    className={classes.code}
                                >
                                    {' '}
                                    code of
                                </span>{' '}
                                ethics
                            </RouterLink>{' '}
                        </li>

                        <li className={classes.item}>
                            <RouterLink href={routes.LEGAL}>
                                ⚖️ legal notice
                            </RouterLink>{' '}
                        </li>

                        {showSocial && (
                            <li className={classes.item}>🔗 social</li>
                        )}
                    </ul>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
