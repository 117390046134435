.header {
    display: flex;
    justify-content: space-between;
    padding-top: 42vh;
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
    color: var(--c-text-secondary);
}

@media (min-width: 700px) {
    .header {
        padding-top: 24px;
    }
}

.claim {
    font-family: 'Bagnard';
    letter-spacing: -0.003em;
    word-break: break-word;
    color: var(--c-text);
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--f-xxxl);
    font-weight: 400;
    line-height: 1.077;
}

@media (min-width: 700px) {
    .nav {
        display: block;
    }
}

.navItem {
    font-size: var(--f-m);
    filter: grayscale(1) blur(0.7px);
}

.navItem+.navItem {
    margin-left: 20px;
}

.activeNavItem {
    color: black;
    display: inline-flex;
    filter: grayscale(0);
}