.logo {
    font-family: 'Bagnard';
    letter-spacing: -0.003em;
    word-break: break-word;
    color: var(--c-text);
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--f-xxxl);
    font-weight: 400;
    line-height: 1.077;
}
