import { FunctionComponent, h } from "preact"
import { Link } from "preact-router"
import { useState, useEffect } from "preact/hooks"
import { PAGES_EN } from "../../core/Pages"
import useOuterClick from "../../hooks/useOuterClick"

import * as classes from "./BurgerMenu.module.css"

export const MENU = [
    {
        emoji: "🛖",
        text: "root",
        href: PAGES_EN.HOME,
    },
    {
        emoji: "🧞",
        text: "about",
        href: PAGES_EN.ABOUT,
    },
    {
        emoji: "🌻",
        text: "sessions",
        href: PAGES_EN.SESSIONS,
    },
    {
        emoji: "🌳",
        text: "register",
        href: PAGES_EN.REGISTER,
    },
]

const BurgerMenu: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useOuterClick<HTMLDivElement>(() => setIsOpen(false))

    useEffect(() => {
        const body = document.body.classList
        const root = document.documentElement.classList

        toggle(body)
        toggle(root)

        return () => {
            document.body.classList.remove("u-overflow-y-hidden")
            document.documentElement.classList.remove("u-overflow-y-hidden")
        }
    }, [isOpen])

    const toggle = (element: DOMTokenList) => {
        isOpen
            ? element.add("u-overflow-y-hidden")
            : element.remove("u-overflow-y-hidden")
    }

    const close = () => setIsOpen(false)

    return (
        <div
            className={`${classes.container} ${isOpen ? classes.active : ""}`}
            ref={ref}
        >
            <div
                className={`${classes.burger}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                &#78033;
            </div>

            <div
                className={classes.drawer}
                style={{ display: isOpen ? "flex" : "none" }}
            >
                <div
                    className={classes.closeIcon}
                    onClick={() => setIsOpen(false)}
                >
                    ✕
                </div>

                <ul className={classes.drawerMenu}>
                    {MENU.map((item) => (
                        <li className={classes.drawerMenuItem}>
                            <Link href={item.href} onClick={close}>
                                {item.emoji} {item.text}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            {isOpen && (
                <div className={classes.backgroundBlur} onClick={close} />
            )}
        </div>
    )
}

export default BurgerMenu
