import { h } from 'preact'
import Router from 'preact-router'
import AsyncRoute from 'preact-async-route'
import Loading from '../components/Loading/Loading'
import useLocale from '../hooks/useLocale'
import { getRoutesByLocale } from './Pages'

const HOME = () => import('../screens/Home').then((module) => module.default)
const ABOUT = () => import('../screens/About').then((module) => module.default)
const CODE = () => import('../screens/Code').then((module) => module.default)
const LEGAL = () => import('../screens/Legal').then((module) => module.default)

const TICKETS = () =>
    import('../screens/Tickets').then((module) => module.default)

const SPEAKERS = () =>
    import('../screens/Speakers').then((module) => module.default)

const SESSIONS = () =>
    import('../screens/Sessions').then((module) => module.default)

const Routes = () => {
    const locale = useLocale()
    const routes = getRoutesByLocale(locale)

    return (
        <main>
            <Router>
                <AsyncRoute
                    path={routes.HOME}
                    getComponent={HOME}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.ABOUT}
                    getComponent={ABOUT}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.CODE}
                    getComponent={CODE}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.LEGAL}
                    getComponent={LEGAL}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.REGISTER}
                    getComponent={TICKETS}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.SPEAKERS}
                    getComponent={SPEAKERS}
                    loading={() => <Loading />}
                />

                <AsyncRoute
                    path={routes.SESSIONS}
                    getComponent={SESSIONS}
                    loading={() => <Loading />}
                />
            </Router>
        </main>
    )
}

export default Routes
