import { FunctionComponent, h } from 'preact'
import * as classes from './Link.module.css'

interface IProps {
    href: string
    primary?: boolean
}

const Link: FunctionComponent<IProps> = (props) => {
    const style = props.primary ? classes.leviating : null
    const primary = props.primary ? classes.primary : null

    return (
        <a href={props.href} className={classes.link}>
            <span className={style}>
                {props.primary ? '➝' : '⤳'}
                {'  '}
            </span>{' '}
            <strong className={primary}>{props.children}</strong>
        </a>
    )
}

export default Link
