import { h, render } from 'preact'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Routes from './core/Routes'
import { IntlProvider } from 'preact-jsx-i18n'
import defaultDefinition from '../src/core/language/en.json'
import { useEffect, useState } from 'preact/hooks'

const colors = ['#068865', '#0030a1', '#6e554d']

const App = () => {
    const [definition, setDefinition] = useState(defaultDefinition)

    const randomElement = colors[Math.floor(Math.random() * colors.length)]

    useEffect(() => {
        const sub = PubSub.subscribe('LANGUAGE', mySubscriber)

        /*document.documentElement.style.setProperty(
            '--highlight-color',
            randomElement
        )*/

        return () => PubSub.unsubscribe(sub)
    }, [])

    // add a switch to DE
    // test out the regular i18n for preact also try out JSON again!

    const mySubscriber = (msg: any, data: 'de' | 'en') => {
        if (data === 'de') {
            import(`../src/core/language/de.json`).then((definition) =>
                setDefinition(definition)
            )
        } else {
            import(`../src/core/language/en.json`).then((definition) =>
                setDefinition(definition)
            )
        }
    }

    return (
        <IntlProvider dictionary={definition}>
            <Header />

            <Routes />
            <Footer />
        </IntlProvider>
    )
}

render(<App />, document.getElementById('root'))
