export const PAGES_EN = {
    HOME: "/",
    ABOUT: "/about/",
    CODE: "/code-of-ethics/",
    LEGAL: "/legal/",
    WORKSHOPS: "/workshops/",
    REGISTER: "/register/",
    SPEAKERS: "/speakers/",
    SESSIONS: "/sessions/",
}

export const PAGES_DE = {
    HOME: "/de/",
    ABOUT: "/de/about/",
    CODE: "/de/code-of-ethics/",
    LEGAL: "/de/impressum/",
    WORKSHOPS: "de/workshops/",
    REGISTER: "de/register/",
    SPEAKERS: "de/speakers/",
    SESSIONS: "de/sessions/",
}

export const getRoutesByLocale = (lang: "de" | "en") => {
    if (lang === "de") {
        return PAGES_DE
    }

    if (lang === "en") {
        return PAGES_EN
    }
}
