.link {
    margin-top: var(--s-m);
    display: block;
    font-size: var(--f-m);
    font-style: bold;
    color: var(--c-secondary);
    display: inline-flex;
}

.link span {
    margin-right: 4px;
}

.link:hover {
    filter: brightness(0.8);
}

.leviating {
    color: var(--c-primary);
    font-size: 20px;
    margin-top: -2px;
    animation: float 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
}

.primary {
    color: var(--c-primary);
}

@keyframes float {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-3px);
    }
    100% {
        transform: translateX(0px);
    }
}
