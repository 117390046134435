import { Fragment, h } from 'preact'
import * as classes from './Logo.module.css'
import useLocale from '../../hooks/useLocale'
import { getRoutesByLocale } from '../../core/Pages'

const Logo = () => {
    const locale = useLocale()
    const routes = getRoutesByLocale(locale)

    return (
        <a href={routes.HOME}>
            <h1 className={classes.logo}>
                Curated <br />
                by Chaos.
            </h1>
        </a>
    )
}

export default Logo
