import { Fragment, h } from 'preact'
import { Match } from 'preact-router/match'
import { FunctionComponent } from 'preact'

interface IProps {
    pathToMatch: string
}

export const NoMatch: FunctionComponent<IProps> = (props) => {
    return (
        <Match path={props.pathToMatch}>
            {({ matches }) => !matches && <Fragment>{props.children}</Fragment>}
        </Match>
    )
}

export const Matches: FunctionComponent<IProps> = (props) => {
    return (
        <Match path={props.pathToMatch}>
            {({ matches }) => matches && <Fragment>{props.children}</Fragment>}
        </Match>
    )
}
