import { h } from 'preact'
import { Link } from 'preact-router/match'
import BurgerMenu, { MENU } from '../Burger/BurgerMenu'
import * as PubSub from 'pubsub-js'
import * as classes from './Header.module.css'
import { useEffect, useState } from 'preact/hooks'
import { getRoutesByLocale } from '../../core/Pages'
import useLocale from '../../hooks/useLocale'
import Logo from '../Logo/Logo'

const Header = () => {
    const [currentLang, setCurrentLang] = useState()

    const locale = useLocale()
    const routes = getRoutesByLocale(locale)

    const change = () => {
        if (currentLang === 'de') PubSub.publish('LANGUAGE', 'en')
        if (currentLang === 'en') PubSub.publish('LANGUAGE', 'de')
        if (!currentLang) PubSub.publish('LANGUAGE', 'de')

        console.log(currentLang)
    }

    useEffect(() => {
        const sub = PubSub.subscribe('LANGUAGE', mySubscriber)
        return () => PubSub.unsubscribe(sub)
    }, [])

    const mySubscriber = (msg: any, data: any) => {
        setCurrentLang(data)
    }

    return (
        <header className={classes.header}>
            <Logo />

            <nav className={classes.nav} hidden>
                <button hidden onClick={change}>
                    switch
                </button>

                {MENU.map((item) => (
                    <Link
                        className={classes.navItem}
                        activeClassName={classes.activeNavItem}
                        href={item.href}
                    >
                        {item.text}
                    </Link>
                ))}
            </nav>

            <BurgerMenu />

            {/* <BurgerMenu />*/}
        </header>
    )
}

export default Header
